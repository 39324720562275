// import React, { useEffect } from "react";

// const TestDisplayMap = () => {
//   useEffect(() => {
//     // Dynamically load Calcite and ArcGIS map components
//     import("https://js.arcgis.com/calcite-components/2.13.2/calcite.esm.js");
//     import("https://js.arcgis.com/4.31/arcgis-map-components.esm.js");

//     // Dynamically add the ArcGIS CSS
//     const link = document.createElement("link");
//     link.rel = "stylesheet";
//     link.href = "https://js.arcgis.com/4.31/esri/themes/light/main.css";
//     document.head.appendChild(link);

//     return () => {
//       document.head.removeChild(link);
//     };
//   }, []);

//   return (
//     <div style={{ height: "100vh", width: "100vw" }}>
//       <arcgis-map item-id="5d1578ee98d846babb039c564ee7ecd5">
//         <arcgis-zoom position="top-left"></arcgis-zoom>
//         <arcgis-legend position="bottom-right"></arcgis-legend>
//       </arcgis-map>
//     </div>
//   );
// };

// export default TestDisplayMap;

import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "../styles/AboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Link } from "react-router-dom";
import "../styles/Aerospace.css";
import ModalFunction from "./Modal";
const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";

const FourtySevenGMap = () => {
  const [locations, setLocations] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);

  // List of addresses
  const addresses = [
    "3818 W 5400 STAYLORSVILLE, UT 84129-3561",
    "1780 N 2000 W# HANGOR21BRIGHAM CITY, UT 84302-4019",
    "1051 N 1000 WLOGAN, UT 84321-6852",
    "1330 GIBSON AVEOGDEN, UT 84404-5530",
    "64 S HORSEHEAD DRMONTICELLO, UT 84535-7951",
    "2147 NORTH RULON WHITE BLVDSTE 106OGDEN, UT 84404-2807",
    "25 S MAIN STSTE 200CENTERVILLE, UT 84014-1842",
    "1194 FLINT MEADOW DRKAYSVILLE, UT 84037-9564",
    "2410 W CALIFORNIA AVESALT LAKE CITY, UT 84104-4526",
  ];

  const googleApiKey = "AIzaSyBwNIm4NF2Dh-T24xsfT0xgiYtRA5OuAgI";

  const navbarRef = useRef(null);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse =
          navbarRef.current.querySelector(".navbar-collapse");
        if (navbarCollapse) {
          navbarCollapse.classList.remove("show"); // Close the navbar
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchCoordinates = async () => {
      const results = [];
      for (const address of addresses) {
        try {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json`,
            {
              params: {
                address: address,
                key: googleApiKey,
              },
            }
          );
          const location = response.data.results[0]?.geometry?.location;
          if (location) {
            results.push({
              lat: location.lat,
              lng: location.lng,
              address,
            });
          }
        } catch (error) {
          console.error(`Error fetching coordinates for ${address}:`, error);
        }
      }
      setLocations(results);
    };

    fetchCoordinates();
  }, [addresses]);

  const mapContainerStyle = {
    width: "100%",
    height: "655px",
  };

  const center = {
    lat: 40.7608, // Center of Utah
    lng: -111.891,
  };

  return (
    <div className="main-wrapper flex flex-col min-h-screen">
      <Navbar
        ref={navbarRef} // Add ref here
        collapseOnSelect
        expand="lg"
        className="fixed top-0 left-0 w-full z-10 flex justify-center items-center"
        style={{
          height: "13vh",
          backgroundColor: "#1e59be",
          zIndex: 5,
          transition: "background-color 0.3s ease",
          position: "fixed",
        }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <div className="flex items-center">
              <img
                className="h-[8vh] md:h-[10vh]"
                src={logo}
                alt="UTahData Logo"
              />
              <h1 className="text-white ml-2 text-lg md:text-4xl">
                UTahData.org
                <span className="beta-label text-xs bg-yellow-500 text-white px-1 py-0.5 rounded-lg">
                  Beta
                </span>
              </h1>
            </div>
          </Navbar.Brand>

          {/* Toggle icon for responsive menu */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            {/* Boxed styling for mobile */}
            <Nav className="d-lg-none bg-white border rounded-lg p-4 shadow-lg flex flex-col items-center">
              <Nav.Link
                as={Link}
                to="/"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>

              {/* <Nav.Link
                as={Link}
                to="/faq"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/about"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
            </Nav>

            {/* Inline styling for larger screens */}
            <Nav className="d-none d-lg-flex ml-auto">
              <Nav.Link
                as={Link}
                to="/"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>
              <ModalFunction
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              <ModalFunction
                show={modalShow}
                onHide={() => setModalShow(false)}
              />

              {/* <Nav.Link
                as={Link}
                to="/faq"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main content */}
      <div className="map-container bg-[#d2eaff] flex-grow">
        <LoadScript googleMapsApiKey={googleApiKey}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={7}
          >
            {locations.map((location, index) => (
              <Marker
                key={index}
                position={{ lat: location.lat, lng: location.lng }}
                title={location.address}
              />
            ))}
          </GoogleMap>
        </LoadScript>
      </div>

      {/* Footer */}
      <footer className="bg-[#0d2969] py-6 md:py-8 flex items-center justify-center">
        <div className="flex flex-col md:flex-row items-center text-center md:text-left space-y-4 md:space-y-0 md:space-x-4">
          {/* Logo Link */}
          <a href="/" className="flex items-center">
            <img className="h-12 md:h-16 mx-2" src={logo} alt="UTahData Logo" />
          </a>
          {/* Build by Text */}
          <span className="text-blue-300 text-sm md:text-base">Built by</span>
          {/* Bodex Link */}
          <a
            href="https://bodex.io/"
            className="flex items-center"
            rel="follow"
          >
            <img
              src="https://bodex.io/wp-content/uploads/2020/11/BODEX-LLC-Logo_00fa00371_3580.png"
              alt="Bodex LLC Logo"
              className="h-8 md:h-10 mx-2"
            />
          </a>
          {/* Using Text */}
          <span className="text-blue-300 text-sm md:text-base">using</span>
          {/* KPI Dashboard Link */}
          <a
            href="https://bodex.io/products/kpi-dash/"
            className="flex items-center"
          >
            <img
              src={kpi}
              alt="KPI Dashboard Logo"
              className="h-12 md:h-16 lg:h-20 mx-2"
            />
          </a>
          {/* Version Link */}
          <Link
            to="/version"
            className="text-blue-300 text-sm md:text-base no-underline"
          >
            20250211.v1.113
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default FourtySevenGMap;
