// import React from 'react'
// import { Link } from 'react-router-dom'
// const logo = "/img/utahData-logo.png";
// const kpi = "/img/KPI-DASH 2.png";

// export default function Footer() {
//   const currentYear = new Date().getFullYear();
//   return (
//     <div>
//         <footer className="bg-[#0d2969] py-6 md:py-8 flex items-center justify-center ">
//         <div >
//         <div className="flex flex-col md:flex-row items-center text-center md:text-left space-y-4  md:space-x-4">
//           {/* Logo Link */}
//           <a href="/" className="flex items-center">
//             <img className="h-12 md:h-16 mx-2" src={logo} alt="UTahData Logo" />
//           </a>
//           {/* Build by Text */}
//           <span className="text-blue-300 text-sm md:text-base">Built by</span>
//           {/* Bodex Link */}
//           <a href="https://bodex.io/products/utahdata/" className="flex items-center">
//             <img
//               src="https://bodex.io/wp-content/uploads/2020/11/BODEX-LLC-Logo_00fa00371_3580.png"
//               alt="Bodex LLC Logo"
//               className="h-8 md:h-10 mx-2"
//             />
//           </a>
//           {/* Using Text */}
//           <span className="text-blue-300 text-sm md:text-base">using</span>
//           {/* KPI Dashboard Link */}
//           <a
//             href="https://bodex.io/products/kpi-dash/"
//             className="flex items-center"
//           >
//             <img
//               src={kpi}
//               alt="KPI Dashboard Logo"
//               className="h-12 md:h-16 lg:h-20 mx-2"
//             />
//           </a>
//           {/* Version Link */}
//           <Link
//             to="/version"
//             className="text-blue-300 text-sm md:text-base no-underline"
//           >
//             20250211.v1.113
//           </Link>
//         </div>
//         </div>
//         <div style={{ textAlign: "center", padding: "1rem" }}>
//         <p style={{ margin: 0 }} className='text-blue-300 '>
//         © {currentYear} UtahData. All rights reserved.
//       </p>
//       </div>

//       </footer>

//     </div>
//   )
// }

import React from "react";
import { Link } from "react-router-dom";

const logo = "/img/utahData-logo.png";
const kpi = "/img/KPI-DASH 2.avif";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <footer className="bg-[#0d2969] pt-9 md:pt-12  flex flex-col items-center justify-center space-y-0 !z-50" style={{position:"relative"}}>
        {/* Top Section with Logos and Links */}
        <div className="flex flex-col md:flex-row items-center text-center md:text-left space-y-0 md:space-y-0 md:space-x-4">
          {/* UtahData Logo */}
          <a href="/" className="flex items-center">
            <img
              className="h-12 md:h-16 mx-2"
              src={logo}
              alt="Utah Data Logo"
            />
          </a>

          {/* "Built by" Text */}
          <span className="text-blue-300 text-sm md:text-base">Built by</span>

          {/* Bodex Logo and Link */}
          <a
            href="https://bodex.io/products/utahdata/"
            className="flex items-center"
          >
            <img
              src="https://bodex.io/wp-content/uploads/2020/11/BODEX-LLC-Logo_00fa00371_3580.png"
              alt="Bodex LLC Logo"
              className="h-8 md:h-10 mx-2"
            />
          </a>

          {/* "Using" Text */}
          <span className="text-blue-300 text-sm md:text-base">using</span>

          {/* KPI Dashboard Logo and Link */}
          <a
            href="https://bodex.io/products/kpi-dash/"
            className="flex items-center"
          >
            <img
              src={kpi}
              alt="KPI Dashboard Logo"
              className="h-12 md:h-16 lg:h-20 mx-2"
            />
          </a>

          {/* Version Text Link */}
          <Link
            to="/version"
            className="text-blue-300 text-sm md:text-base no-underline"
          >
            20250211.v1.113
          </Link>
        </div>

        {/* Copyright Message */}
        <div className="text-center">
          <p className="text-blue-300 text-sm md:text-base">
            © {currentYear} UTahData. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}
