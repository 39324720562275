import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import ModalFunction from "./Modal";

const map = "/img/utah1.webp";
const county = "/img/counties1.png";
const city = "/img/city2.png";
const industries = "/img/industries1.png";
const jobs = "/img/jobs.png";
const population = "/img/population.png";
const unemployment = "/img/unemployment.webp";
const university = "/img/university.png";

const kpi = "/img/KPI-DASH 2.png";
const logo = "/img/1.png";
const utahCounty = "/img/utah-counties1.webp";
const utahCities = "/img/utah-city.webp";
const utahPopulation = "/img/utah-population.jpg";
const utahJobs = "/img/utah-jobs.jpg";
const utahUniversity = "/img/utah-universities.jpg";
const utahUnemployment = "/img/unemployment-rate.jpeg";
const utahIndustries = "/img/utah-industries.jpg";
const school = "/img/glance-school2.png";
const officialLogo = "/img/utah-logo-new.png";
const utahFlag = "/img/utah-flag.webp";
const statepark = "/img/state-logo-new.png";
const nationalpark = "/img/national-park-new.png";
const geothermal = "/img/red-geo.png";
const airforce = "/img/af-logo-seal.png";
const governor = "/img/governor-img.webp";
const airport = "/img/airheliport.png";
const vertiport = "/img/vertiport.png";
const zip = "/img/zip.png";

export default function UtahAtGlance() {
  // const data = [
  //   { label: "Total Counties in Utah", value: "29", icon: <img src={county} alt="Counties" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahCounty},
  //   { label: "Total Cities in Utah", value: "329 (2024)", icon: <img src={city} alt="city" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahCities},
  //   { label: "Total Population of Utah", value: "3.45 Million (2024)", icon: <img src={population} alt="population" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahPopulation },
  //   { label: "Total Jobs In Utah", value: "1,769,700 (2024)" , icon: <img src={jobs} alt="jobs" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahJobs},
  //   { label: "Total Universities in Utah", value: "36 (2024)", icon: <img src={university} alt="university" className="h-20 w-18 filter brightness-0 invert" />  , backgroundImage: utahUniversity},
  //   { label: "Total Unemployment Rate in Utah", value: "3.50% (2024)" , icon: <img src={unemployment} alt="unemployment" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahUnemployment},
  //   { label: "Total Industries in Utah", value: "645 (2024)" , icon: <img src={industries} alt="industries" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahIndustries},
  // ];

  // const data = [
  //   { label: "Total Counties in Utah", value: "29", icon: <img src={county} alt="Counties" className="h-20 w-18 filter " />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Cities in Utah", value: "329 (2024)", icon: <img src={city} alt="City" className="h-20 w-18 filter" />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]"},
  //   { label: "Total Population of Utah", value: "3.45 Million (2024)", icon: <img src={population} alt="Population" className="h-20 w-18 filter" />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]"},
  //   { label: "Total Jobs In Utah", value: "1,769,700 (2024)", icon: <img src={jobs} alt="Jobs" className="h-20 w-18 filter " />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Universities in Utah", value: "36 (2024)", icon: <img src={university} alt="University" className="h-24 w-18 filter " />,gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Unemployment Rate in Utah", value: "3.50% (2024)", icon: <img src={unemployment} alt="Unemployment" className="h-20 w-18 filter" />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Industries in Utah", value: "645 (2024)", icon: <img src={industries} alt="Industries" className="h-20 w-18 filter " />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  // ];

  const data = [
    {
      label: "Governor's Corner",
      // value: "Gov. Spencer J. Cox",
      icon: (
        <img
          src={governor}
          alt="governor"
          className="h-[4rem] w-[19rem] filter "
        />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-governor",
    },
    {
      label: "Counties in Utah",
      value: "29",
      icon: <img src={county} alt="Counties" className="h-20 w-18 filter " />,
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-counties",
    },
    {
      label: "Cities in Utah",
      value: "329 (2024)",
      icon: <img src={city} alt="City" className="h-20 w-18 filter" />,
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-cities",
    },
    {
      label: "Zip Codes in Utah",
      value: "294 (2024)",
      icon: <img src={zip} alt="Zip code" className="h-20 w-18 filter" />,
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-zipcodes",
    },
    {
      label: "Total Population of Utah",
      value: "3.45 Million (September 2024)",
      icon: (
        <img src={population} alt="Population" className="h-20 w-18 filter" />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-gender-population",
    },
    {
      label: "Jobs In Utah",
      value: "1.77 million (September 2024)",
      icon: <img src={jobs} alt="Jobs" className="h-20 w-18 filter " />,
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-jobs",
    },
    {
      label: "Universities in Utah",
      value: "36 (2024)",
      icon: (
        <img src={university} alt="University" className="h-24 w-18 filter " />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-universities",
    },
    {
      label: "Unemployment Rate in Utah",
      value: "3.50% (September 2024)",
      icon: (
        <img
          src={unemployment}
          alt="Unemployment"
          className="h-20 w-18 filter"
        />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-unemployment-rate",
    },
    {
      label: "Industries in Utah",
      value: "21 Industries (2024)",
      icon: (
        <img src={industries} alt="Industries" className="h-20 w-18 filter " />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-industries",
    },
    {
      label: "Schools in Utah",
      value: "School District 41 (2023)\nNumber of Schools : 956",
      icon: <img src={school} alt="Industries" className="h-20 w-18 filter " />,
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-schools",
    },
    {
      label: "National Parks in Utah",
      value: "Number of National Parks : 5",
      icon: (
        <img
          src={nationalpark}
          alt="National Parks"
          className="h-20 w-18 filter "
        />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-nationalparks",
    },
    {
      label: "State Parks in Utah",
      value: "Number of State Parks : 46",
      icon: (
        <img src={statepark} alt="Industries" className="h-24 w-24 filter " />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-stateparks",
    },
    {
      label: "Geothermal Hotspots in Utah",
      value: "Total : 2981",
      icon: (
        <img src={geothermal} alt="geothermal" className="h-24 w-24 filter " />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-geothermal-hotspots",
    },
    {
      label: "Hill Air Force Base(HAFB)",
      // value: "Total : 2981",
      icon: <img src={airforce} alt="airforce" className="h-24 w-24 filter " />,
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-hill-airforce-base",
    },

    {
      label: "Airports and Heliports in Utah",
      value: "Total : 138",
      icon: (
        <img
          src={airport}
          alt="Airports and Heliports in Utah"
          className="h-24 w-26 filter "
        />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-airports-heliports",
    },
    {
      label: "Vertiports in Utah",
      value: "",
      icon: (
        <img
          src={vertiport}
          alt="Vertiports in Utah"
          className="h-24 w-26 filter "
        />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/utah-vertiports",
    },
  ];

  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);
  const [modalShow, setModalShow] = React.useState(false);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (navbarRef.current && !navbarRef.current.contains(event.target)) {
  //       setExpanded(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar Section */}

      <div
        className="relative flex-grow flex flex-col items-center justify-center mt-20 px-4 bg-cover bg-center bg-no-repeat "
        aria-label="Map Utah"
        style={{ backgroundImage: `url(${map})` }}
      >
        {/* Overlay */}
        {/* Dark overlay */}
        <div className="absolute inset-0 bg-blue-700 opacity-60"></div>

        {/* Content with relative positioning to sit above the overlay */}
        <div className="relative text-center mt-5 p-1 rounded-md  inline-block px-6 py-2">
          <h1 className="text-3xl md:text-6xl font-bold text-[#ffc107]">
            <div className="flex items-center gap-4">
              <img
                src={officialLogo}
                alt="Utah Logo"
                className="w-[5rem] sm:w-[7rem] h-[5rem] sm:h-[7rem]"
              ></img>
              <h1>Utah at a Glance</h1>
              <img
                src={utahFlag}
                className="w-[6rem] sm:w-[8rem] h-[6rem] sm:h-[6rem]"
                alt="Utah Flag"
              ></img>
            </div>
          </h1>
        </div>

        <div className="relative flex flex-wrap justify-center items-stretch gap-8 p-6 rounded-lg">
          {data.map((item, index) => (
            <div
              key={index}
              className={`relative p-4 w-full sm:w-1/2 md:w-1/4 flex flex-col justify-center items-center text-center
      overflow-hidden rounded-lg shadow-lg transform transition-transform duration-100 ease-in-out
      hover:scale-105 hover:shadow-2xl group  ${item.gradientClass}`}
            >
              <Link to={item.link} className="no-underline">
                {/* Background Image with Scaling Effect */}
                <div
                  className="absolute inset-0 bg-cover bg-center transform transition-transform duration-100 ease-in-out group-hover:scale-150 "
                  style={{ backgroundImage: `url(${item.backgroundImage})` }}
                ></div>

                {/* Overlay for Dark Effect */}
                {/* <div className="absolute inset-0 bg-blue-800 opacity-30"></div> */}

                {/* Content on top of the background */}
                <div className="relative z-10 flex flex-col items-center">
                  <div className="mb-3 opacity-80">{item.icon}</div>
                  <strong className="block text-blue-800 text-xl">
                    {item.label}
                  </strong>
                  <span className="block text-blue-800 text-lg">
                    {item.value}
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
    </div>
  );
}
