import React from "react";
import { Link } from "react-router-dom";

const nationalPark = "/img/national-park-bg.jpg";

function NationalPark() {
  const park = [
    {
      "S.no": 1,
      Name: "Arches National Park",
      "County Name": "Grand",
      Address: "PO Box 907, Moab, UT 84532",
      Website: "https://www.nps.gov/arch/index.htm",
      "Contact No": "435-719-2299",
      link: "/utah-at-a-glance/utah-nationalparks/arches",
    },
    {
      "S.no": 2,
      Name: "Bryce Canyon National Park",
      "County Name": "Garfield and Kane",
      Address: "Highway 63, Bryce, UT 84764",
      Website: "https://www.nps.gov/brca/index.htm",
      "Contact No": "435-834-5322",
      link: "/utah-at-a-glance/utah-nationalparks/bryce-canyon",
    },
    {
      "S.no": 3,
      Name: "Canyonlands National Park",
      "County Name": "San Juan, Wayne, Garfield, and Grand",
      Address: "2282 Southwest Resource Boulevard, Moab, UT 84532",
      Website: "https://www.nps.gov/cany/index.htm",
      "Contact No": "435-719-2313",
      link: "/utah-at-a-glance/utah-nationalparks/canyonland",
    },
    {
      "S.no": 4,
      Name: "Capitol Reef National Park",
      "County Name": "Wayne, Garfield, Sevier, and Emery",
      Address: "HC 70 Box 15, Torrey, UT 84775",
      Website: "https://www.nps.gov/care/index.htm",
      "Contact No": "435-425-3791",
      link: "/utah-at-a-glance/utah-nationalparks/capitolreef",
    },
    {
      "S.no": 5,
      Name: "Zion National Park",
      "County Name": "Washington, Iron, and Kane",
      Address: "Springdale, UT 84767",
      Website: "https://www.nps.gov/zion/index.htm",
      "Contact No": "435-772-3256",
      link: "/utah-at-a-glance/utah-nationalparks/zion",
    },
  ];

  return (
    <div>
      <div
        className="min-h-screen mt-[1rem]  flex flex-col items-center justify-center"
        style={{ backgroundColor: "rgb(210, 234, 255)" }}
      >
        <div className="overflow-x-auto w-full mb-[3rem]">
          {/* <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      National Parks in Utah
      <div className='mt-2 font-bold text-2xl'>(5 National Parks)</div> */}
          <div
            className=" bg-[#d2eaff] flex flex-col items-center justify-center"
            style={{
              backgroundImage: `url(${nationalPark})`, // Replace with your image path
              backgroundSize: "cover", // Ensures the image covers the entire container
              backgroundPosition: "center", // Centers the image
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
              width: "100%", // Ensures the container takes full width of the screen
              height: "45vh", // Makes the container span the full height of the viewport
            }}
          >
            <h2
              className="text-white text-center px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl mt-7"
              style={{
                color: "white",
                textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)", // Makes the text stand out
                lineHeight: "1.2", // Adjusts line spacing
              }}
            >
              National Parks
            </h2>
            <div className="mt-2 font-bold text-2xl" style={{ color: "#fff" }}>
              (5 National Parks)
            </div>

            <span
              className="text-center px-4 text-lg sm:text-2xl md:text-3xl lg:text-4xl mt-4"
              style={{
                color: "#fff",
                fontFamily: "SignPainter", // Use the SignPainter font
                textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)", // Adds subtle shadow
                lineHeight: "1.5", // Adjusts line spacing
              }}
            >
              Explore Utah By The Numbers
            </span>
          </div>
        </div>

        {/* <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full"> */}
        <div className="overflow-x-auto w-[85%] bg-white shadow-md rounded-lg p-4">
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                {/* <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  S.no
                </th> */}
                <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  Name
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  County Name
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  Address
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  Website
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  Contact No
                </th>
              </tr>
            </thead>
            <tbody>
              {park.map((park) => (
                <tr key={park.sn} className="hover:bg-gray-100">
                  {/* <td className="border border-gray-300 px-4 py-2 text-center ">
                    {park["S.no"]}
                  </td> */}

                  <td className="border border-gray-300 px-4 py-2 text-center">
                    <Link to={park.link} className="no-underline font-bold">
                      {park.Name}
                    </Link>
                  </td>

                  <td className="border border-gray-300 px-4 py-2">
                    {park["County Name"]}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {park.Address}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center ">
                    <a
                      href={park.Website}
                      target="_blank"
                      rel=" noopener noreferrer"
                    >
                      {park.Website}
                    </a>
                  </td>

                  <td className="border border-gray-300 px-4 py-2">
                    {park["Contact No"]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default NationalPark;
